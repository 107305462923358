import { v4 } from 'uuid';
import TYPES from '@/types';
import Vue from 'vue';

// Application
import { SearchOnBoardingStepsQuery } from '@/modules/my-investment/on-boarding-steps/application/queries';
import {
  SearchInvestmentProvidersQuery,
} from '@/modules/my-investment/investment-provider/application/queries';
import { CreateOnBoardingStepCommand } from '@/modules/my-investment/on-boarding-steps/application/commands';
import UpdateKuspitOnBoardingStepCommand
  from '@/modules/my-investment/kuspit/kuspit-account-linking/application/commands/update-kuspit-on-boarding-step-command';

// Domain
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import { OnBoardingStepEntity } from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  OnBoardingStepKuspitAccountEntity,
} from '@/modules/my-investment/kuspit/kuspit-account-linking/domain/entity/on-boarding-step-kuspit-account-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class KuspitUserViewModel {
  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  readonly search_investment_providers_query!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  readonly search_on_boarding_steps_query!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.UPDATE_KUSPIT_ON_BOARDING_STEP_COMMAND)
  private readonly update_on_boarding_step_command!: UpdateKuspitOnBoardingStepCommand;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  readonly create_on_boarding_step_command!: CreateOnBoardingStepCommand;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  i18n_namespace = 'components.onboarding-kuspit.kuspit_user';

  small_screen: null | boolean = null;

  internal_step = 1;

  i_am_user = false;

  investment_provider_id = '';

  on_boarding_step_id = '';

  exists_step = false;

  exists_step_started = false;

  is_loading = false;

  private on_boarding_step: OnBoardingStepKuspitAccountEntity = {
    id: '',
    current_step: 'contract_selection_or_account_linking_step',
    investment_provider_id: '',
    customer_id: sessionStorage.getItem('user_id')!,
    payload: {},
  }

  private on_boarding_step_started: OnBoardingStepKuspitAccountEntity = {
    id: v4(),
    current_step: 'on_boarding_kuspit_status',
    investment_provider_id: '',
    customer_id: sessionStorage.getItem('user_id')!,
    payload: {
      id: sessionStorage.getItem('user_id')!,
      email_address: '',
      status: 'started',
    },
  }

  private readonly view!: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  setStepOnBoardingId = (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === 'contract_selection_or_account_linking_step',
    );

    if (search_step) {
      this.on_boarding_step.id = search_step.id!;
      this.on_boarding_step.investment_provider_id = search_step.investment_provider_id;
      this.exists_step = true;
    }

    const search_step_status = on_boarding_steps.find(
      (step) => step.current_step === 'on_boarding_kuspit_status',
    );

    if (search_step_status) {
      this.exists_step_started = true;
    }
  };

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === 'kuspit',
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  saveStep = async (is_user: boolean) => {
    try {
      if (this.exists_step) {
        this.on_boarding_step.payload.i_am_user = is_user;
        await this.update_on_boarding_step_command.execute(this.on_boarding_step);
      } else {
        this.on_boarding_step.id = this.on_boarding_step_id;
        this.on_boarding_step.investment_provider_id = this.investment_provider_id;
        this.on_boarding_step.payload = {
          i_am_user: is_user,
        };
        await this.create_on_boarding_step_command.execute(this.on_boarding_step);
        this.exists_step = true;
      }

      if (!this.exists_step_started && !is_user) {
        this.on_boarding_step_started.investment_provider_id = this.investment_provider_id;
        await this.create_on_boarding_step_command.execute(this.on_boarding_step_started);
        this.exists_step_started = true;
      }
    } catch {
      return false;
    }
    return true;
  }

  setOnboardingStepId = () => {
    this.on_boarding_step_id = v4();
  }

  nextStep = async () => {
    const step_saved = await this.saveStep(false);
    if (step_saved) { this.view.$emit('nextStep'); }
  }

  closeModal = async () => {
    const step_saved = await this.saveStep(true);
    this.i_am_user = true;
    if (step_saved) { this.view.$emit('closeModal'); }
  }

  initialize = async () => {
    this.small_screen = getScreenSizeVariant() === 'small';
    this.is_loading = true;
    const investment_providers = await this.search_investment_providers_query.execute();
    this.setInvestmentProviderId(investment_providers);
    const step = await this.search_on_boarding_steps_query.execute(this.investment_provider_id);
    this.setStepOnBoardingId(step);
    this.is_loading = false;
    this.view.$emit('loadingInfo', false);
  }
}
