






























































import { Component, Vue } from 'vue-property-decorator';
import KuspitUserViewModel from '@/vue-app/view-models/components/contracts/kuspit-user-view-model';

@Component({
  name: 'KuspitUser',
  components: {
    LinkAccount: () => import('./LinkAccount.vue'),
    KuspitAccountLinkingModal: () => import('@/vue-app/components/contract-savings/kuspit-account-linking/kuspitAccountLinkingModal.vue'),
    kuspitAccountLinkingExistingUser: () => import('@/vue-app/components/contract-savings/kuspit-account-linking/KuspitAccountLinkingAccountWithoutContract.vue'),
  },
})
export default class KuspitUser extends Vue {
  kuspit_user_view_model = Vue.observable(
    new KuspitUserViewModel(this),
  );

  mounted() {
    this.kuspit_user_view_model.setOnboardingStepId();
  }

  created() {
    this.kuspit_user_view_model.initialize();
  }
}

